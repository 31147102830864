import { ErrorBoundary } from "components/ErrorBoundary.tsx";
import { MainPageWrapper } from "components/Layout/MainPageWrapper.tsx";
import { type MetaFunction, data } from "react-router";
import { generateMetatags } from "utils/generateMetatags.ts";
export { ErrorBoundary };

export const loader = () => {
  throw data(
    { error: "La page n'a pas été trouvée" },
    {
      status: 404,
    },
  );
};

export const meta: MetaFunction = ({ location }) => {
  return generateMetatags({
    noIndex: true,
    pageUrl: location.pathname,
  });
};

export default function FourOhFour() {
  return (
    <MainPageWrapper color="white">
      <ErrorBoundary />
    </MainPageWrapper>
  );
}
